

import React, { useEffect } from 'react'
import ComingSoon from '../components/comingSoon';
export default function PrestaShop() {
    useEffect(() => {
        document.title = 'Spinnennetz - PrestaShop';
    }, []);
    return (
        <>
            <ComingSoon />
        </>
    )
}