import React from 'react'

export default function DesignDetail() {
    return (
        <>
            <div className='detail-section cSection'>
                <div className='cContainer'>
                    <div className='detailWrapper'>
                        <h1 class="title"><span>Elevate Your</span><small> Brand with Stunning Designs</small> </h1>
                        <p className='text-1'>we offer a full range of design services to enhance your brand's visual appeal and user experience. Whether you need a sleek UI/UX design, a memorable logo, eye-catching leaflets, or engaging social media banners, our talented design team is here to bring your vision to life.</p>
                    </div>
                </div>
            </div>
        </>
    )
}