import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ContactForm() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));

        // Check if input field is filled and add class accordingly
        const parent = e.target.parentNode;
        if (value.trim() !== '') {
            parent.classList.add('filled');
            parent.classList.remove('error');
        } else {
            parent.classList.remove('filled');
        }

    };
    const [errors, setErrors] = useState({});



    const handleFocus = (e) => {
        const { name } = e.target;
        e.target.parentNode.classList.add('focus');
    };

    const handleBlur = (e) => {
        e.target.parentNode.classList.remove('focus');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = {};
        if (formData.firstName.trim() === '') {
            errors.firstName = 'First Name is required';
        }
        if (formData.lastName.trim() === '') {
            errors.lastName = 'Last Name is required';
        }
        if (formData.email.trim() === '') {
            errors.email = 'Email is required';
        } else if (!isValidEmail(formData.email)) {
            errors.email = 'Invalid email format';
        }
        if (formData.phoneNumber.trim() === '') {
            errors.phoneNumber = 'Phone Number is required';
        } else if (!isValidPhoneNumber(formData.phoneNumber)) {
            errors.phoneNumber = 'Invalid phone number format';
        }
        if (formData.companyName.trim() === '') {
            errors.companyName = 'Company name is required';
        }
        if (formData.subject.trim() === '') {
            errors.subject = 'Subject is required';
        }
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        fetch('https://thespinnennetz.com/sn-forms/contact.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then(response => {
                // if (!response.ok) {
                //     throw new Error('Network response was not ok');
                // }
                console.log('Form submitted successfully', response);
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    companyName: '',
                    subject: '',
                    message: ''
                });
                navigate("/thank-you-contact");
            })
            .catch(error => {
                console.error('There was a problem with your fetch operation:', error);
            });
    };

    const isValidPhoneNumber = (phoneNumber) => {
        // Basic phone number validation (digits only, at least 7 digits)
        const re = /^(\+?1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/;
        return re.test(phoneNumber);
    };

    const isValidEmail = (email) => {
        // Basic email validation
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    return (
        <>
            <div className="form-section contact cSection">
                <div className="cContainer">
                    <div className="form-wrapper">
                        <h3>SEND US A MESSAGE</h3>
                        <form onSubmit={handleSubmit} enctype="multipart/form-data">
                            <div className={`field-wrapper ${errors.firstName ? 'error' : ''}`}>
                                <label>First Name</label>
                                <input type="text" name="firstName" value={formData.firstName} placeholder="First Name" onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                                {errors.firstName && <div className="error">{errors.firstName}</div>}
                            </div>
                            <div className={`field-wrapper ${errors.lastName ? 'error' : ''}`}>
                                <label>Last Name</label>
                                <input type="text" name="lastName" value={formData.lastName} placeholder="First Name" onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}
                                />
                                {errors.lastName && <div className="error">{errors.lastName}</div>}
                            </div>
                            <div className={`field-wrapper ${errors.email ? 'error' : ''}`}>
                                <label>Email:</label>
                                <input type="email" name="email" value={formData.email} placeholder="Email" onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                                {errors.email && <div className="error">{errors.email}</div>}
                            </div>
                            <div className={`field-wrapper ${errors.phoneNumber ? 'error' : ''}`}>
                                <label>Phone Number</label>
                                <input type="tel" name="phoneNumber" value={formData.phoneNumber} placeholder="Phone Number" onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                                {errors.phoneNumber && <div className="error">{errors.phoneNumber}</div>}
                            </div>
                            <div className={`field-wrapper ${errors.companyName ? 'error' : ''}`}>
                                <label>Company Name</label>
                                <input type="text" name="companyName" value={formData.companyName} placeholder="Company Name" onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                                {errors.companyName && <div className="error">{errors.companyName}</div>}
                            </div>
                            <div className={`field-wrapper ${errors.subject ? 'error' : ''}`}>
                                <label>Subject</label>
                                <input type="text" name="subject" value={formData.subject} placeholder="Subject" onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                                {errors.subject && <div className="error">{errors.subject}</div>}
                            </div>
                            <div className={`field-wrapper full-width ${errors.name ? 'error' : ''}`}>
                                <label>Message</label>
                                <textarea
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}
                                />
                            </div>
                            <button type="submit" className='cBtn btn'>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

