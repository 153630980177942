import React from "react";
export default function CaseStudy() {
    return (
        <>
            <div className="caseStudy-section cSection">
                <div className="cContainer">
                    <div className="caseStudy-wrapper">
                        <div className="caseStudy-title">
                            <h2 className="section-title">Our recent<span> Case studies</span></h2>
                        </div>
                        <div className="caseStudy-data">
                            {Data.map((d) => (
                                <div className="main-data">
                                    <div className="img-wraper">
                                        <div className="imgWrap">
                                            <img src={d.img} alt={d.title} />
                                        </div>
                                    </div>
                                    <div className="data-content">
                                        <div className="data-wrap">
                                            <h3>{d.title}</h3>
                                            <p className="subtext">{d.para}</p>
                                            <a href={d.readmorelink} target="_blank" className="readmoreLink"><span>Read more</span>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.78132 7.99999L5.48132 4.69999L6.42399 3.75732L10.6667 7.99999L6.42399 12.2427L5.48132 11.3L8.78132 7.99999Z" fill="url(#paint0_linear_263_365)" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_263_365" x1="10.6667" y1="3.75732" x2="3.11576" y2="8.37163" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#F76680" />
                                                            <stop offset="1" stop-color="#57007B" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div ></>
    )

}
const Data = [
    {
        img: require('../asstes/Case-study-1.jpg'),
        title: 'QR Generator',
        para: `  Scan. Generator. Share. Simplified. Elevate your QR code experience with QR Master. Effortlessly scan and create custom codes with style. Stand out with fancy banners and personalized designs. Share your codes instantly and make a lasting impression. Simple. Stylish.`,
        readmorelink: `https://play.google.com/store/apps/details?id=com.scanandgenerate&hl=en_IN&gl=US`,
    },
    {
        img: require('../asstes/Case-study-2.jpg'),
        title: 'Age Finder',
        para: `Introducing the Age Calculator app, the ultimate tool for effortlessly determining your age with precision. Whether you need to calculate your age for personal reasons, administrative purposes, or just out of curiosity, this app provides you with accurate results in a matter of seconds. Say goodbye to manual calculations and welcome the convenience of the Age Calculator app on your mobile device.`,
        readmorelink: `https://play.google.com/store/apps/details?id=com.sn_agefinder&hl=en_IN&gl=US`
    },
    {
        img: require('../asstes/Case-study-3.jpg'),
        title: 'BMI Calculator',
        para: `This application will help you to Seamlessly track your body's health, calculate your BMI with precision, and gain valuable insights to achieve and maintain your ideal weight. Empower yourself with personalized recommendations and take control of your well-being. Experience the power of data-driven health management today!`,
        readmorelink: `https://play.google.com/store/apps/details?id=com.bmianalyzer&hl=en_IN&gl=US`
    },
]