import React, { useEffect } from 'react'
import DesignDetail from '../components/Design-detail';
import Designdata from '../components/Design-data';
import LeadDesign from '../components/Lead-design';
export default function Design() {
    useEffect(() => {
        document.title = 'Spinnennetz - Design';
    }, []);
    return (
        <>
            <DesignDetail/>
            <Designdata/>
            <LeadDesign/>
        </>
    )
}