import React, { useState } from 'react';
// import TechImg from '../asstes/tect-image.jpg'
export default function Tech() {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    return (
        <>
            <div className="tech-section cSection">
                <div className="cContainer">
                    <div className="tech-wrapper">
                        <div className="techTitle">
                            <h2 className="section-title">Our
                                <span>Tech Stack</span></h2>
                            {/* <ul className="techLink">
                                {links.map((d) => (
                                    <li><a href="/">{d.img}</a></li>
                                ))}
                            </ul>
                            <div className="tab-content">
                                {activeTab === 0 && <p>Content for Tab 1</p>}
                                {activeTab === 1 && <p>Content for Tab 2</p>}
                                {activeTab === 2 && <p>Content for Tab 3</p>}
                            </div> */}
                            <div className="techLink">
                                <button onClick={() => handleTabClick(0)} className={activeTab === 0 ? 'active' : ''}>Frontend</button>
                                <button onClick={() => handleTabClick(1)} className={activeTab === 1 ? 'active' : ''}>UX UI</button>
                                <button onClick={() => handleTabClick(2)} className={activeTab === 2 ? 'active' : ''}>A/B Testing</button>
                                <button onClick={() => handleTabClick(3)} className={activeTab === 3 ? 'active' : ''}>CMS</button>
                                <button onClick={() => handleTabClick(4)} className={activeTab === 4 ? 'active' : ''}>E-Commerce</button>
                                <button onClick={() => handleTabClick(5)} className={activeTab === 5 ? 'active' : ''}>Browsers</button>
                                <button onClick={() => handleTabClick(6)} className={activeTab === 6 ? 'active' : ''}>DevOps</button>
                            </div>
                            <div className="tab-content">
                                {activeTab === 0 && <ul className="tabImg">
                                    {Frontend.map((d) => (
                                       <div className='TechLogo'> <img src={d.img} alt={d.alt} title={d.alt}/></div>
                                    ))}
                                </ul>}
                                {activeTab === 1 && <ul className="tabImg">
                                    {UXUI.map((d) => (
                                      <div  className='TechLogo'> <img src={d.img} alt={d.alt} title={d.alt}/></div>
                                    ))}
                                </ul>
                                 }
                                {activeTab === 2 &&  <ul className="tabImg">
                                    {AB.map((d) => (
                                      <div  className='TechLogo'> <img src={d.img} alt={d.alt} title={d.alt}/></div>
                                    ))}
                                </ul>}
                                {activeTab === 3 &&  <ul className="tabImg">
                                    {CMS.map((d) => (
                                      <div  className='TechLogo'> <img src={d.img} alt={d.alt} title={d.alt}/></div>
                                    ))}
                                </ul>}
                                {activeTab === 4 && <ul className="tabImg">
                                    {eCommerce.map((d) => (   
                                   <div  className='TechLogo'> <img src={d.img} alt={d.alt} title={d.alt}/></div>
                                    ))}
                                </ul>}
                                {activeTab === 5 &&  <ul className="tabImg">
                                    {browser.map((d) => (
                                   <div  className='TechLogo'> <img src={d.img} alt={d.alt} title={d.alt}/></div>
                                    ))}
                                </ul>}
                                {activeTab === 6 &&  <ul className="tabImg">
                                    {devOps.map((d) => (
                                   <div  className='TechLogo'> <img src={d.img} alt={d.alt} title={d.alt}/></div>
                                    ))}
                                </ul>}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}
var Frontend = [
    {
        img:require('../asstes/html5.svg').default,
        alt: 'HTML'
    },
    {
        img:require('../asstes/css3.svg').default,
        alt: 'CSS'
    },
    {
        img:require('../asstes/bootstrap5.svg').default,
        alt: 'Bootstrap'
    },
    {
        img:require('../asstes/jquery.svg').default,
        alt: 'Jquery'
    },
    {
        img:require('../asstes/javascript.svg').default,
        alt: 'Javascript'
    },
    {
        img:require('../asstes/react.svg').default,
        alt: 'React'
    },
    {
        img:require('../asstes/sass.svg').default,
        alt: 'Sass'
    },
    {
        img:require('../asstes/less.svg').default,
        alt: 'Less'
    },
    
]
var  UXUI = [
    {
        img:require('../asstes/figma1.svg').default,
        alt: 'Figma'
    },
    {
        img:require('../asstes/ps.svg').default,
        alt: 'PS'
    },
    {
        img:require('../asstes/ai.svg').default,
        alt: 'AI'
    },
    {
        img:require('../asstes/xd.svg').default,
        alt: 'XD'
    },
    {
        img:require('../asstes/coreldraw.svg').default,
        alt: 'Coreldraw'
    },
    
]
var  AB = [
    {
        img:require('../asstes/adobe target.svg').default,
        alt: 'Adobe Target'
    },
    {
        img:require('../asstes/optimizely.svg').default,
        alt: 'Optimizely'
    },
    {
        img:require('../asstes/vwo.svg').default,
        alt: 'VWO'
    },
    {
        img:require('../asstes/kameleoon.svg').default,
        alt: 'Kameleoon'
    },
    {
        img:require('../asstes/convert.svg').default,
        alt: 'Convert'
    },
    {
        img:require('../asstes/AB.svg').default,
        alt: 'AB'
    },
    {
        img:require('../asstes/clickfunnels.svg').default,
        alt: 'Clickfunnels'
    },
]
var  CMS = [
    {
        img:require('../asstes/webflow.svg').default,
        alt: 'Webflow'
    },
    {
        img:require('../asstes/sitefinity-tech.svg').default,
        alt: 'Sitefinity'
    },
    {
        img:require('../asstes/marketo-tech.svg').default,
        alt: 'Marketo'
    },
    {
        img:require('../asstes/hubspot-tech.svg').default,
        alt: 'Hubspot'
    },
    {
        img:require('../asstes/Wix-tech.svg').default,
        alt: 'Webflow'
    },
    {
        img:require('../asstes/wordpress.svg').default,
        alt: 'Wordpress'
    },
    {
        img:require('../asstes/Unbounce.svg').default,
        alt: 'Unbounce'
    }
]
var  eCommerce = [
    {
        img:require('../asstes/woocommerce.svg').default,
        alt: 'Woocommerce'
    },
    {
        img:require('../asstes/Shopify1.svg').default,
        alt: 'Shopify1'
    },
    {
        img:require('../asstes/Prestashop.svg').default,
        alt: 'Prestashop'
    },
]
var  browser = [
    {
        img:require('../asstes/chrome.svg').default,
        alt: 'Chrome'
    },
    {
        img:require('../asstes/firefox.svg').default,
        alt: 'Firefox'
    },
    {
        img:require('../asstes/safari.svg').default,
        alt: 'Safari'
    },
    {
        img:require('../asstes/edge.svg').default,
        alt: 'Edge'
    },
    {
        img:require('../asstes/opera.svg').default,
        alt: 'Opera'
    },
]
var  devOps = [
    {
        img:require('../asstes/Asana.svg').default,
        alt: 'Asana'
    },
    {
        img:require('../asstes/git.svg').default,
        alt: 'Git'
    },
    {
        img:require('../asstes/github.svg').default,
        alt: 'Github'
    },
    {
        img:require('../asstes/gitlab.svg').default,
        alt: 'Gitlab'
    },
    {
        img:require('../asstes/cloudinary.svg').default,
        alt: 'Cloudinary'
    },
]
