import React, { useEffect } from 'react'
import Basicweb from '../components/basic-webdata'
import BasicWebDetail from '../components/basicWeb-detail'
import LeadBasicweb from '../components/Lead-basicweb'

export default function BasicWeb() {
    useEffect(() => {
        document.title = 'Spinnennetz - Basic Web';
    }, []);
    return (
        <>
        <BasicWebDetail/>
        <Basicweb/>
        <LeadBasicweb/>
        </>
    )
}