import React, { useEffect } from 'react';
// import ComingSoon from '../components/comingSoon';
import ContactData from '../components/contact-data';
import ContactForm from '../components/contact-form'

export default function Contact() {
    useEffect(() => {
        document.title = 'Spinnennetz - Contact';
    }, []);
    return (
        <>
            <ContactData />
            <ContactForm />
            {/* <ComingSoon /> */}
        </>
    );
}