import React, { useEffect } from 'react'
import ServiceDetail from '../components/service-detail'
import ServiceData from '../components/service-data'
// import ComingSoon from '../components/comingSoon';

export default function Service() {
    useEffect(() => {
        document.title = 'Spinnennetz - Service';
    }, []);
    return (
        <>
            {<ServiceDetail />}
            {<ServiceData />}
            {/* <ComingSoon /> */}
        </>
    );
}