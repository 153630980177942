

import React, { useEffect } from 'react'
import ShopifyData from '../components/shopify-data.js'
import PlanTable from '../components/shopifyPlan.js'
export default function Shopify() {
    useEffect(() => {
        document.title = 'Spinnennetz - Shopify';
    }, []);
    return (
        <>
            <ShopifyData />
            <PlanTable />
        </>
    )
}