

import React , {useEffect} from 'react'
// import ComingSoon from '../components/comingSoon';
import HireData from '../components/hire-data';
import Hireform from '../components/hire-form'
export default function Careers() {
    useEffect(() => {
        document.title = 'Spinnennetz - Careers';
    }, []);
    return (
        <>
            <HireData />
            <Hireform />
            {/* <ComingSoon /> */}
        </>
    );
}