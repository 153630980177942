
import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Review() {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    return (
        <>
            <div className="review-section cSection">
                <div className="cContainer">
                    <div className="review-wrapper">
                        <div className="review-details">
                            <div className="mainTitle">
                                <h2 className="section-title">Why cutomers love
                                    <span>Working with us</span></h2>
                            </div>
                            <div className="reviewData">
                                <div className="reviewContent">
                                    <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} className="data-slider">
                                        {Content.map((d) => (
                                            <p>{d.data}</p>
                                        ))}
                                    </Slider>
                                </div>
                                <div className="reviewStar">
                                    <Slider className="review-slider"
                                        asNavFor={nav1}
                                        ref={(slider2) => setNav2(slider2)}
                                        slidesToShow={3}
                                        swipeToSlide={true}
                                        focusOnSelect={true}
                                        centerMode={true}
                                        autoplay={true}
                                        responsive={[
                                            {
                                                breakpoint: 665,
                                                settings: {
                                                    slidesToShow: 1,
                                                    centerMode: false,
                                                    dots: true,
                                                    arrows: false,
                                                },
                                            },
                                        ]}
                                    >
                                        {reveiw.map((i) => (
                                            <div className="imgWrap">
                                                <img src={i.img} alt="" />
                                                <span>{i.name}</span>
                                                <p className='subtext'>{i.intro}</p>
                                            </div>
                                        ))}
                                    </Slider>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}





const Content = [
    {
        data: `Working with Spinnenntze was a game-changer for our business. Their team's expertise and professionalism exceeded our expectations. They provided innovative solutions that streamlined our operations and improved efficiency. Highly recommend!`,
    },
    {
        data: `Spinnenntze has been our trusted IT partner for years, and for good reason. Their team is incredibly responsive and always goes the extra mile to ensure our systems are running smoothly. Their proactive approach to maintenance has saved us time and headaches.`,
    },
    {
        data: `Choosing Spinnenntze for our IT needs was one of the best decisions we've made. Their support team is knowledgeable, friendly, and always available to assist us.`,
    },
    {
        data: `From the initial consultation to ongoing support, Spinnenntze has been a pleasure to work with. Their dedication to customer satisfaction is evident in every interaction. No question or concern goes unanswered, and they always prioritize our needs.`,
    },
    {
        data: `Spinnenntze's Shopify expertise is unparalleled! Their team provided seamless implementation and ongoing support, resulting in a stunning online store that exceeded our expectations. Highly recommend for anyone looking to elevate their e-commerce store!`,
    },

]
const reveiw = [
    {
        img: require('../asstes/review-star.jpg'),
        name: 'Pamela Redmond',
        intro: 'CEO',
    },
    {
        img: require('../asstes/review-star.jpg'),
        name: 'Ellie Freeman',
        intro: 'Sr. developer',
    },
    {
        img: require('../asstes/review-star.jpg'),
        name: 'Jom H',
        intro: 'Sr. Director',
    },
    {
        img: require('../asstes/review-star.jpg'),
        name: 'Ava Carney',
        intro: 'CTO',
    },
    {
        img: require('../asstes/review-star.jpg'),
        name: 'Austin Sullivan',
        intro: 'Founder',
    },

]


// export default function Review() {
//     const [nav1, setNav1] = useState();
//     const [nav2, setNav2] = useState();

//     return (
//         <div>
//             <h2>Slider Syncing (AsNavFor)</h2>
//             <h4>First Slider</h4>
//             <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
//                 <div>
//                     <h3>1</h3>
//                 </div>
//                 <div>
//                     <h3>2</h3>
//                 </div>
//                 <div>
//                     <h3>3</h3>
//                 </div>
//                 <div>
//                     <h3>4</h3>
//                 </div>
//                 <div>
//                     <h3>5</h3>
//                 </div>
//                 <div>
//                     <h3>6</h3>
//                 </div>
//             </Slider>
//             <h4>Second Slider</h4>
//             <Slider
//                 asNavFor={nav1}
//                 ref={(slider2) => setNav2(slider2)}
//                 slidesToShow={3}
//                 swipeToSlide={true}
//                 focusOnSelect={true}
//                 autoplay={true}
//             >
//                 <div>
//                     <h3>1</h3>
//                 </div>
//                 <div>
//                     <h3>2</h3>
//                 </div>
//                 <div>
//                     <h3>3</h3>
//                 </div>
//                 <div>
//                     <h3>4</h3>
//                 </div>
//                 <div>
//                     <h3>5</h3>
//                 </div>
//                 <div>
//                     <h3>6</h3>
//                 </div>
//             </Slider>
//         </div>
//     );
// }