

import React from 'react'
import { Link } from 'react-router-dom';

export default function Herosection() {
    return (
        <div className="hero-section cSection">
            <div className="cContainer">
                <div className="hero-wrapper">
                    <div className="left-content">
                        <h1 className='title'><small>Transform <span>Your Business</span> with  Our</small> <span>Innovative <b>Solutions</b></span></h1>
                        <p className='text-1'>Looking for a website development company that can help take your business to the next level? Look no further than Spinnennetz Our team of experts is dedicated to crafting custom web solutions that are as unique as your brand. From design to development to launch, we work with you every step of the way to create a website that truly represents your business and drives growth.
                        </p>
                        <Link to="/contact" className='cBtn btn'>Let’s get started!</Link>
                    </div>
                    <div className="right-content">
                        <img src={require('../asstes/Hero-image.jpg')} alt="Hero Image" />
                    </div>
                </div>
            </div>
        </div>
    )
}