import React from "react";
export default function LeadDesign() {
    return (
        <>
            <div className="lead-section basicWeb cSection">
                <div className="cContainer">
                    <div className="lead-wrapper">
                        <div className="lead-data">
                            <h2>Let's Create Something Amazing</h2>
                            <p>Ready to take your brand to the next level with stunning designs? Contact us today to discuss your project and see how we can help you achieve your goals. Let's create something amazing together!</p>
                        </div>
                        <div className="lead-btn">
                                <a  href="/contact" className="cbtn-1">Hire us today</a>
                            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="191" viewBox="0 0 120 191" fill="none">
                                <rect width="34.467" height="6.60908" rx="3.30454" transform="matrix(-0.601033 -0.799224 0.914216 -0.405227 20.7158 40)" fill="url(#paint0_linear_313_538)" />
                                <rect x="55.3234" y="31.8086" width="31.8084" height="6.97684" rx="3.48842" transform="rotate(-90 55.3234 31.8086)" fill="url(#paint1_linear_313_538)" />
                                <rect width="34.8564" height="6.5509" rx="3.27545" transform="matrix(0.638227 -0.769848 0.898472 0.43903 91.3234 36.8135)" fill="url(#paint2_linear_313_538)" />
                                <rect width="34.4666" height="6.60906" rx="3.30453" transform="matrix(-0.601039 0.79922 0.914219 0.405221 20.7158 151)" fill="url(#paint3_linear_313_538)" />
                                <rect width="31.8078" height="6.97684" rx="3.48842" transform="matrix(0 1 1 0 55.3235 159.191)" fill="url(#paint4_linear_313_538)" />
                                <rect width="34.8561" height="6.55088" rx="3.27544" transform="matrix(0.638233 0.769843 0.898475 -0.439024 91.3235 154.186)" fill="url(#paint5_linear_313_538)" />
                                <defs>
                                    <linearGradient id="paint0_linear_313_538" x1="34.467" y1="0" x2="32.0223" y2="12.7494" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFEF5E" />
                                        <stop offset="1" stop-color="#F7936F" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_313_538" x1="87.1318" y1="31.8086" x2="84.2117" y2="45.1218" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFEF5E" />
                                        <stop offset="1" stop-color="#F7936F" />
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_313_538" x1="34.8564" y1="0" x2="32.4781" y2="12.6548" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFEF5E" />
                                        <stop offset="1" stop-color="#F7936F" />
                                    </linearGradient>
                                    <linearGradient id="paint3_linear_313_538" x1="34.4666" y1="0" x2="32.0219" y2="12.7493" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFEF5E" />
                                        <stop offset="1" stop-color="#F7936F" />
                                    </linearGradient>
                                    <linearGradient id="paint4_linear_313_538" x1="31.8078" y1="0" x2="28.8877" y2="13.3132" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFEF5E" />
                                        <stop offset="1" stop-color="#F7936F" />
                                    </linearGradient>
                                    <linearGradient id="paint5_linear_313_538" x1="34.8561" y1="0" x2="32.4777" y2="12.6548" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFEF5E" />
                                        <stop offset="1" stop-color="#F7936F" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}