import React from "react";

export default function WebmaintenanceData() {
    return (
        <>
            <div className="build-section basicWeb cSection">
                <div className="cContainer">
                    <div className="build-wrapper">
                        <div className="build-data">
                            {dataData.map((i) => (
                                <div className="dataWrapper cms" id={i.link}>
                                    <div className="leftBuild">
                                        <div className="details">
                                            <h3>{i.title}</h3>
                                            <ul className="list-style-1">
                                                {
                                                    i.list.map((j) => (
                                                        <li><p className="text"><span>{j.title}</span> {j.copy}</p></li>
                                                    ))
                                                }
                                                {/* <li><p className="text"><span>{i.subTitle1}</span> {i.li1} </p></li>
                                            <li><p className="text"><span>{i.subTitle2}</span> {i.li2}</p> </li>
                                            <li><p className="text"><span>{i.subTitle3}</span> {i.li3} </p></li>
                                            <li><p className="text"><span>{i.subTitle4}</span> {i.li4} </p></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="rightBuild">
                                        <img src={i.img} alt={i.title} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const dataData = [
    {
        img: require('../asstes/SSL-management.png'),
        title: `SSL Management`,
        list: [
            {
                "title": `SSL Certificate Installation: `,
                "copy": `We handle the installation of SSL certificates to secure your website and protect your visitors' data.`,
            },
            {
                "title": `Renewals and Updates: `,
                "copy": `Regular monitoring and renewal of SSL certificates to ensure continuous security and compliance.`,
            },
            {
                "title": `Troubleshooting: `,
                "copy": `Quick resolution of any SSL-related issues to maintain trust and credibility.`,
            },
        ]
    },
    {
        img: require('../asstes/Data-management.png'),
        title: `Data Management`,
        list: [
            {
                "title": `Backup Solutions: `,
                "copy": `Regular backups of your website data to prevent loss and ensure quick recovery in case of emergencies.`,
            },
            {
                "title": `Data Integrity Checks: `,
                "copy": `Routine checks to ensure your data is accurate and intact.`,
            },
            {
                "title": `Database Optimization: `,
                "copy": `Regular optimization of your database to improve site performance and speed.`,
            },
        ]
    },
    {
        img: require('../asstes/site-transfer.png'),
        title: `Site Transfer`,
        list: [
            {
                "title": `Seamless Migration: `,
                "copy": `Smooth transfer of your website to a new hosting provider with minimal downtime and disruption.`,
            },
            {
                "title": `Data Migration: `,
                "copy": `Safe and secure migration of all your data, including files, databases, and emails.`,
            },
            {
                "title": `Testing and Verification: `,
                "copy": `Post-transfer testing to ensure everything functions correctly on the new server.`,
            },
        ]
    },
    {
        img: require('../asstes/Domain-transfer.png'),
        title: `Domain Transfer`,
        list: [
            {
                "title": `Registrar Coordination: `,
                "copy": `Handling the entire process of transferring your domain to a new registrar.`,
            },
            {
                "title": `DNS Management: `,
                "copy": `Updating DNS settings to ensure seamless domain transfer and uninterrupted service.`,
            },
            {
                "title": `Domain Renewals: `,
                "copy": `Managing domain renewals to prevent expiration and loss of domain ownership.`,
            },
        ]
    },
    {
        img: require('../asstes/security-update.png'),
        title: `Security Updates`,
        list: [
            {
                "title": `Regular Patching: `,
                "copy": `Applying security patches and updates to your website’s software to protect against vulnerabilities.`,
            },
            {
                "title": `Malware Scanning: `,
                "copy": `Regular scans for malware and other threats, with prompt removal and remediation.`,
            },
            {
                "title": `Security Audits: `,
                "copy": `Comprehensive audits to identify and fix potential security weaknesses.`,
            },
        ]
    },
    {
        img: require('../asstes/performance-optimization.png'),
        title: `Performance Optimization`,
        list: [
            {
                "title": `Speed Enhancements: `,
                "copy": `Implementing techniques to improve website load times and performance.`,
            },
            {
                "title": `Caching Solutions: `,
                "copy": `Setting up and managing caching to enhance speed and efficiency.`,
            },
            {
                "title": `Resource Optimization: `,
                "copy": `Regular analysis and optimization of website resources to maintain peak performance.`,
            },
        ]
    },
    {
        img: require('../asstes/content-update.png'),
        title: `Content Updates`,
        list: [
            {
                "title": `Regular Content Refresh: `,
                "copy": `Keeping your website content fresh and up-to-date to engage your audience.`,
            },
            {
                "title": `Content Management:  `,
                "copy": `Assistance with adding, updating, and managing content as needed.`,
            },
            {
                "title": `SEO Maintenance: `,
                "copy": `Ongoing SEO optimization to ensure your content ranks well in search engines.`,
            },
        ]
    },
    {
        img: require('../asstes/technical-support.png'),
        title: `Technical Support`,
        list: [
            {
                "title": `24/7 Support: `,
                "copy": `Round-the-clock technical support to address any issues or concerns promptly.`,
            },
            {
                "title": `Issue Resolution: `,
                "copy": ` Quick and efficient resolution of any technical problems that may arise.`,
            },
            {
                "title": `User Training: `,
                "copy": `Providing training and support for your team to effectively manage the website.`,
            }
        ]
    }
]