

import React, { useEffect } from 'react'
// import CmsData from '../components/cms-data'
import Cmsweb from '../components/cms-webdata'
import CMSWebDetail from '../components/cmsWeb-detail'
import LeadCMSweb from '../components/Lead-cmsweb'
export default function CmsWeb() {
    useEffect(() => {
        document.title = 'Spinnennetz - CMS Web';
    }, []);
    return (
        <>
        <CMSWebDetail/>
        {/* <CmsData /> */}
        <Cmsweb/>
        <LeadCMSweb/>
        </>
    )
}