import React from 'react'

export default function CMSWebDetail() {
    const handleClick = (e) => {
        const elamTop = document.querySelector('#' + e.target.getAttribute('data-id')).offsetTop;
        window.scrollTo({
            top: elamTop,
            behavior: 'auto' // Optional, adds smooth scrolling behavior
        });
    };
    return (
        <>
            <div className='detail-section basicWeb cSection hero-section cms'>
                <div className='cContainer'>
                    <div className='detailWrapper'>
                        <h1 class="title"><span>Comprehensive CMS</span><small> Solutions Tailored for Your Needs</small> </h1>
                        <p className='text-1'>we excel in providing comprehensive CMS (Content Management System) solutions that empower you to manage your website with ease. Our experienced team is proficient with a variety of CMS platforms, ensuring that you get the best tool suited for your business requirements.</p>
                        <div className="hero-wrapper">
                            <div className="left-content">
                                <div class="cms-logo">
                                    <ul>
                                        {CMS.map((i) => (
                                            <li>
                                            <a href='javascript:;' data-id= {i.alt} onClick={handleClick}>
                                            <img src={i.img} alt={i.alt} title={i.alt} />
                                            </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
var CMS = [
    {
        img: require('../asstes/webflow-icon.svg').default,
        alt: 'Webflow'
    },
    {
        img: require('../asstes/sitefinity.svg').default,
        alt: 'Sitefinity'
    },
    {
        img: require('../asstes/marketo.svg').default,
        alt: 'Marketo'
    },
    {
        img: require('../asstes/hubspot.svg').default,
        alt: 'HubSpot'
    },
    {
        img: require('../asstes/Wix.svg').default,
        alt: 'Wix'
    },
    {
        img: require('../asstes/wordpress.svg').default,
        alt: 'Wordpress'
    },
    {
        img:require('../asstes/Unbounce.svg').default,
        alt: 'Unbounce'
    }
]