import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Hireform() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        apply: '',
        file: null,
        experience: '',
        mobile: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        const newValue = type === 'file' ? files[0] : value; // Handle file input separately
        setFormData(prevData => ({
            ...prevData,
            [name]: newValue
        }));

        // Check if input field is filled and add class accordingly
        const parent = e.target.parentNode;
        if (value.trim() !== '') {
            parent.classList.add('filled');
            parent.classList.remove('error');
        } else {
            parent.classList.remove('filled');
        }
    };

    const [errors, setErrors] = useState({});

    const handleFocus = (e) => {
        e.target.parentNode.classList.add('focus');
    };

    const handleBlur = (e) => {
        e.target.parentNode.classList.remove('focus');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = {};
        if (formData.name.trim() === '') {
            errors.name = 'Person Name is required';
        }
        if (formData.apply.trim() === '') {
            errors.apply = 'Apply Data is required';
        }
        if (formData.email.trim() === '') {
            errors.email = 'Email is required';
        } else if (!isValidEmail(formData.email)) {
            errors.email = 'Invalid email format';
        }

        if (formData.mobile.trim() === '') {
            errors.mobile = 'Mobile Number is required';
        } else if (!isValidPhoneNumber(formData.mobile)) {
            errors.mobile = 'Invalid mobile number format';
        }

        if (formData.experience.trim() === '') {
            errors.experience = 'Experience is required';
        }

        if (!formData.file) {
            errors.file = 'File is required';
        }

        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        const formDataToSend = new FormData();
        for (let key in formData) {
            formDataToSend.append(key, formData[key]);
        }

        fetch('https://thespinnennetz.com/sn-forms/hire.php', {
            method: 'POST',
            body: formDataToSend,
        })
            .then(response => {
                // if (!response.ok) {
                //     throw new Error('Network response was not ok');
                // }
                console.log('Form submitted successfully', response);
                setFormData({
                    name: '',
                    email: '',
                    apply: '',
                    file: '',
                    experience: '',
                    mobile: '',
                    message: ''
                });
                navigate("/thank-you-careers");
            })
            .catch(error => {
                console.error('There was a problem with your fetch operation:', error);
            });
    };

    const isValidPhoneNumber = (phoneNumber) => {
        // Basic phone number validation (digits only, at least 7 digits)
        const re = /^(\+?1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/;
        return re.test(phoneNumber);
    };

    const isValidEmail = (email) => {
        // Basic email validation
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    return (
        <>
            <div className="form-section contact cSection">
                <div className="cContainer">
                    <div className="form-wrapper">
                        <h3>APPLY  HERE</h3>
                        <form onSubmit={handleSubmit} enctype="multipart/form-data">
                            <div className={`field-wrapper ${errors.name ? 'error' : ''}`}>
                                <label>Contact Person Name:</label>
                                <input type="text" name="name" placeholder="Person Name" value={formData.name} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                                {errors.name && <div className="error">{errors.name}</div>}
                            </div>
                            <div className={`field-wrapper ${errors.email ? 'error' : ''}`}>
                                <label>Email Address:</label>
                                <input type="email" name="email" placeholder="Email Address" value={formData.email} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                                {errors.email && <div className="error">{errors.email}</div>}
                            </div>
                            <div className={`field-wrapper ${errors.apply ? 'error' : ''}`}>
                                <label>Apply For:</label>
                                <input type="text" name="apply" placeholder="Apply For" value={formData.apply} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                                {errors.apply && <div className="error">{errors.apply}</div>}
                            </div>
                            <div className={`field-wrapper ${errors.file ? 'error' : ''}`}>
                                {/* <label>Resume:</label> */}
                                <input type="file" name="file" placeholder="Resume" onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                                {errors.file && <div className="error">{errors.file}</div>}
                            </div>
                            <div className={`field-wrapper ${errors.experience ? 'error' : ''}`}>
                                <label>Experience:</label>
                                <input type="text" name="experience" placeholder="Experience" value={formData.experience} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                                {errors.experience && <div className="error">{errors.experience}</div>}
                            </div>
                            <div className={`field-wrapper ${errors.mobile ? 'error' : ''}`}>
                                <label>Mobile:</label>
                                <input type="tel" name="mobile" placeholder="Mobile" value={formData.mobile} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                                {errors.mobile && <div className="error">{errors.mobile}</div>}
                            </div>
                            <div className="field-wrapper full-width">
                                <label>Message:</label>
                                <textarea
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <button type="submit" className='cBtn btn'>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}