import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './variable.css'
import './common.css'
import ReactGA from "react-ga4";
import { BrowserRouter as Router, Routes, Route, Form } from "react-router-dom";
import Header from './components/header';
import About from './pages/about';
import Careers from './pages/careers';
import Shopify from './pages/shopify';
import WooCommerce from './pages/wooCommerce';
import Ecommerce from './pages/e-commerce';
import PrestaShop from './pages/prestaShop';
// import ServiceOffer from './components/Service-offer';
import Home from './pages/home';
import Footer from './components/footer';
import Products from './pages/Product';
import Service from './pages/service';
import Contact from './pages/contact';
import CmsWeb from './pages/cms-web';
import BasicWeb from './pages/basic-web';
import WebMaintenance from './pages/web-maintenance';
import Design from './pages/design';
import Cro from './pages/cro';
import ThankYouContact from './pages/thank-you-contact';
import ThankYouCarrers from './pages/thank-you-careers';
import OopsData from './pages/oops';
import { useEffect } from 'react';

ReactGA.initialize("G-6LDJSL2WNF");

function App() {
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
  }, []);
  return (
    <>
      <Router>
        <div className='pgWrapper'>
          <Header />
          <div className='mainContent'>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/products-solutions" element={<Products />} />
              <Route path="/service" element={<Service />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/cms-web" element={<CmsWeb />} />
              <Route path="/basic-web" element={<BasicWeb />} />
              <Route path="/web-maintenance" element={<WebMaintenance />} />
              <Route path="/shopify" element={<Shopify />} />
              <Route path="/wooCommerce" element={<WooCommerce />} />
              <Route path="/Ecommerce" element={<Ecommerce />} />
              <Route path="/prestaShop" element={<PrestaShop />} />
              <Route path="/CRO" element={<Cro />} />
              <Route path="/design" element={<Design />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/thank-you-contact" element={<ThankYouContact />} />
              <Route path="/thank-you-careers" element={<ThankYouCarrers />} />
              <Route path="*" element={<OopsData />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
