import React from "react";
export default function BasicCroDetail(){
    return(
        <>
            <div className='detail-section basic cSection'>
                <div className='cContainer'>
                    <div className='detailWrapper'>
                        <h1 class="title"><span>CRO</span><small> Configuration & Setup</small> </h1>
                        <p className='text-1'>we specialize in Conversion Rate Optimization (CRO) to help you turn more visitors into customers. Our comprehensive CRO services involve configuring and setting up all the necessary tools and strategies to enhance your website’s performance and increase your conversion rates.</p>
                    </div>
                </div>
            </div>
        </>
    )
}