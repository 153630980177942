import React from 'react'
export default function ComingSoon() {
    return (
        <>
            <div className='comingsoon-section'>
                <div className='cContainer'>
                    <div className='comingWrapper'>
                        <h1>Coming Soon</h1>
                        <p>We're working hard to bring you something amazing. Stay tuned!</p>
                        <div className='btnWrapper'>
                            <a href='/' className="cBtn btn">Go Home</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}