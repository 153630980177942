

import React , { useEffect } from 'react'
import ComingSoon from '../components/comingSoon';
import BasicCroDetail from '../components/Basiccro-detail';
import BasiccroData from '../components/basi-crodata';
import Leadcro from '../components/Lead-cro';

export default function Cro() {
    useEffect(() => {
        document.title = 'Spinnennetz - CRO';
    }, []);
    return (
        <>
            <BasicCroDetail/> 
            <BasiccroData/>
            <Leadcro/>
          
        </>
    );
}